import { useQuery } from "@tanstack/react-query";
import ApiService from "./ApiService";

export async function apiGetTenant(params) {
  return ApiService.fetchData({
    url: "/tenant/",
    method: "get",
    params,
  });
}

export const useQueryApiGetTenant = (params) => {
  return useQuery({
    queryKey: ["apiGetTenant"],
    queryFn: () => apiGetTenant(params),
    select: (response) => {
      return response.data;
    },
  });
};
useQueryApiGetTenant.queryKey = "apiGetTenant";
