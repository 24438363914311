import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter  } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import './locales'
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ChakraTheme from "configs/theme.chakra";

const environment = process.env.NODE_ENV;

if (appConfig.enableMock) {
  mockServer({ environment });
}

function App() {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<BrowserRouter history={history}>
						<Theme>
						<ChakraTheme>
							<Layout />

							<ReactQueryDevtools initialIsOpen={false} />
						</ChakraTheme>
						</Theme>
					</BrowserRouter >
				</PersistGate>
			</Provider>
		</QueryClientProvider>
	)
}

export default App;
