export const signInUserData = [
    {
        id: '21',
        avatar: '/img/avatars/thumb-robot-1.jpeg',
        userName: 'Thiago Cunha',
        email: 'admin@alice.ai',
        authority: ['admin', 'user'],
        password: '123Qwe',
        accountUserName: 'admin'
    },
    // {
    //     id: '22',
    //     avatar: '/img/avatars/thumb-robot-1.jpeg',
    //     userName: 'Universidade de Princeton',
    //     email: 'princeton@alice.ai',
    //     authority: ['educational_institution'],
    //     password: '123Qwe',
    //     accountUserName: 'escola'
    // },
    // {
    //     id: '23',
    //     avatar: '/img/avatars/thumb-robot-1.jpeg',
    //     userName: 'Albert Einstein',
    //     email: 'albert.einstein@alice.ai',
    //     authority: ['admin', 'teacher'],
    //     password: '123Qwe',
    //     accountUserName: 'professor'
    // },
    {
        id: '24',
        avatar: '/img/avatars/thumb-robot-1.jpeg',
        userName: 'J. Robert Oppenheimer',
        email: 'oppenheimer@alice.ai',
        authority: ['user'],
        password: '123Qwe',
        accountUserName: 'oppenheimer'
    }
]