import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { apiRefreshToken, apiSignIn, apiSignUp } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import React, { useState } from 'react';


function useAuth() {
  // FORCE LOG OUT
  // useEffect(() => {
  // 	dispatch(onSignOutSuccess())
  // 	dispatch(setUser(initialState))
  // 	navigate(appConfig.unAuthenticatedEntryPath)
  // }, [])

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const [showVerifyOtpComponent, setShowVerifyOtpComponent] = useState(false)

  const { token, signedIn, refreshToken } = useSelector(
    (state) => state.auth.session
  );

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      
      if (resp.data) {
        const { access_token, refresh_token } = resp.data;
        const dateNow = new Date();
        const dateToRefresh = new Date(dateNow.setDate(dateNow.getDate() + 1));

        dispatch(
          onSignInSuccess({
            token: access_token,
            refreshToken: refresh_token,
            lastLogin: dateNow,
            nextRefresh: dateToRefresh,
          })
        );
        if (resp.data.user) {
          const { photo_url, name, email, role, user_id } = resp.data.user;
          dispatch(
            setUser({
              avatar: photo_url || "/img/avatars/thumb-robot-1.jpeg",
              userName: name,
              authority: [role],
              email: email,
              role: role,
              userId: user_id,
              tenantData: resp.data.tenant,
            })
          );
        }

        if(resp.data.two_factor_required === true){
          setShowVerifyOtpComponent(true);

          return {
            status: "two_factor_required",
            message: resp.data.message
          };
        }

        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);

        return {
          status: "success",
          message: resp.data.message
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { access_token, refresh_token } = resp.data;
        const dateNow = new Date();
        const dateToRefresh = new Date(dateNow.setDate(dateNow.getDate() + 1));

        dispatch(
          onSignInSuccess({
            token: access_token,
            refreshToken: refresh_token,
            lastLogin: dateNow,
            nextRefresh: dateToRefresh,
          })
        );
        if (resp.data.user) {
          const { photo_url, name, email, role, userId } = resp.data.user;
          dispatch(
            setUser({
              avatar: photo_url || "/img/avatars/thumb-robot-1.jpeg",
              userName: name,
              authority: ["user"],
              email: email,
              role: role,
              userId: userId,
            })
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleRefreshToken = async () => {
    try {
      const resp = await apiRefreshToken({
        refresh_token: refreshToken,
      });
      if (resp.data) {
        const { access_token, refresh_token } = resp.data;
        const dateNow = new Date();
        const dateToRefresh = new Date(dateNow.setDate(dateNow.getDate() + 1));

        dispatch(
          onSignInSuccess({
            token: access_token,
            refreshToken: refresh_token,
            lastLogin: dateNow,
            nextRefresh: dateToRefresh,
          })
        );

        return true;
      }
    } catch (errors) {
      return false;
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    showVerifyOtpComponent,
    signUp,
    signOut,
    refreshToken: handleRefreshToken,
  };
}

export default useAuth;
