import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { REQUEST_TENANT } from "constants/app.constant";
import { colors } from "constants/theme.colors";
import { useQueryApiGetTenant } from "services/TenantService";

const ChakraTheme = ({ children }) => {
  const { data } = useQueryApiGetTenant({ slug: REQUEST_TENANT });

  const primaryColor = !!data ? data.details?.colors?.primary_color : "blue";
  const secondaryColor = !!data
    ? data.details?.colors?.secondary_color
    : "pink";

  const theme = extendTheme({
    colors: {
      primary: colors[primaryColor],
      secondary: colors[secondaryColor],
    },
    components: {
      Button: {
        defaultProps: { colorScheme: primaryColor === "black" ? "gray" : "primary" },
        baseStyle: {
          textTransform: "initial",
        },
      },
      Tabs: {
        defaultProps: { colorScheme: "primary" },
      },
    },
    fonts: {
      heading: `'Inter', sans-serif`,
      body: `'Inter', sans-serif`,
    },
    breakpoints: {
      base: "0em",
      sm: "576px",
      md: "768px",
      lg: "992px",
      xl: "1200px",
      "2xl": "1400px",
    },

    styles: {
      global: {
        "html, body": {
          lineHeight: "tall",
        },
        // a: {
        //   color: "primary.500",
        // },
      },
    },
  });

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};
export default ChakraTheme;
