import React from "react";
import { ConfigProvider } from "components/ui";
import useDarkMode from "utils/hooks/useDarkMode";
import { themeConfig } from "configs/theme.config";
import { useQueryApiGetTenant } from "services/TenantService";
import { REQUEST_TENANT } from "constants/app.constant";

const Theme = (props) => {
  const [isDark] = useDarkMode();
  const { data } = useQueryApiGetTenant({ slug: REQUEST_TENANT });
  const primaryColor = !!data ? data.details?.colors?.primary_color : "blue";

  const currentTheme = {
    mode: isDark ? "dark" : "light",
    ...themeConfig,
    themeColor: primaryColor,
  };

  return (
    <>
      <ConfigProvider value={currentTheme}>{props.children}</ConfigProvider>
    </>
  );
};

export default Theme;
