import React from "react";
import { BsFileText } from "react-icons/bs";
import { GrDocumentText, GrDocumentTxt } from "react-icons/gr";
import { VscFilePdf, VscFileZip, VscFile, VscPlayCircle } from "react-icons/vsc";

const BYTE = 1000;
const getKB = (bytes) => Math.round(bytes / BYTE);

const getSizeFile = (bytes) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1000;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  let size = (bytes / Math.pow(k, i)) * 10;

  size = Math.ceil(size) / 10;

  return size % 1 === 0 ? size + ' ' + sizes[i] : size.toFixed(1) + ' ' + sizes[i];
};

const FileIcon = ({ children }) => {
  return <span className="text-4xl">{children}</span>;
};

const FileItem = (props) => {
  const { file, children } = props;
  const { type, name, size } = file;

  const renderThumbnail = () => {
    const isImageFile = type.split("/")[0] === "image";

    if (isImageFile) {
      return (
        <img
          className="upload-file-image"
          src={URL.createObjectURL(file)}
          alt={`file preview ${name}`}
        />
      );
    }

    if (type === "application/zip") {
      return (
        <FileIcon>
          <VscFileZip />
        </FileIcon>
      );
    }

    if (type === "application/pdf") {
      return (
        <FileIcon>
          <VscFilePdf />
        </FileIcon>
      );
    }

    if (type === "text/plain") {
      return (
        <FileIcon>
          <GrDocumentTxt size={31} />
        </FileIcon>
      );
    }

    if (type === "video/mp4" || type === "video/avi" || type === "video/quicktime") {
      return (
        <FileIcon>
          <VscPlayCircle />
        </FileIcon>
      );
    }

    return (
      <FileIcon>
        <VscFile />
      </FileIcon>
    );
  };

  return (
    <div className="upload-file">
      <div className="flex w-[50%] flex-start p-2">
        <div className="upload-file-thumbnail">{renderThumbnail()}</div>
        <div className="upload-file-info" style={{marginLeft: 0}}>
          <h6 className="upload-file-name">{name}</h6>
          {!!size ? (
            <span className="upload-file-size">{getSizeFile(size)}</span>
          ) : null}
        </div>
      </div>
      {children}
    </div>
  );
};

export default FileItem;
