import axios from "axios";
import appConfig from "configs/app.config";
import {
  TOKEN_TYPE,
  REQUEST_HEADER_AUTH_KEY,
  REQUEST_HEADER_CLIENT,
} from "constants/api.constant";
import { PERSIST_STORE_NAME, REQUEST_TENANT } from "constants/app.constant";
import deepParseJson from "utils/deepParseJson";
import store from "../store";
import { onSignOutSuccess } from "../store/auth/sessionSlice";
import { setToken } from '../store/auth/sessionSlice';

const BaseService = axios.create({
  timeout: 60000,
  baseURL: `${appConfig.baseUrlProd}/${appConfig.apiPrefix}`,
});

BaseService.interceptors.request.use(
  (config) => {
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
    const persistData = deepParseJson(rawPersistData);

    const accessToken = persistData.auth.session.token;

    if (accessToken) {
      config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
    }

    config.headers[REQUEST_HEADER_CLIENT] = REQUEST_TENANT;

    return config;
  },
  (error) => {
    console.error("Erro na configuração da requisição:", error);
    return Promise.reject(error);
  }
);

async function handle401Error(error, originalRequest) {
  if (
    error?.response?.status !== 401 ||
    originalRequest?._retry ||
    originalRequest?.url === 'auth/refresh-token' ||
    originalRequest?.url === 'auth/sign-in'
  ) {
    return Promise.reject(error);
  }

  originalRequest._retry = true;

  try {
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
    const persistData = deepParseJson(rawPersistData);
    const refreshToken = persistData.auth.session.refreshToken;

    const isLoginRoute = originalRequest?.url?.includes('auth/sign-in');

    if (!isLoginRoute) {
      const newAccessToken = await renewAccessToken(refreshToken);

      if (newAccessToken) {
        store.dispatch(setToken(newAccessToken));

        originalRequest.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE} ${newAccessToken}`;

        return BaseService(originalRequest);
      }
    }
  } catch (renewError) {
    store.dispatch(onSignOutSuccess());
    return Promise.reject(renewError);
  }
}


BaseService.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    return handle401Error(error, error.config);
  }
);

async function renewAccessToken(refreshToken) {
  try {
    const response = await BaseService.post('auth/refresh-token', {
      refresh_token: refreshToken,
    });

    const newAccessToken = response.data.access_token;

    store.dispatch(setToken(newAccessToken));

    return newAccessToken;
  } catch (error) {
    console.error('Erro ao renovar o access token:', error);
    throw error;
  }
}

export default BaseService;
